<script setup>
import { ref } from "vue";

const coreServices = ref([
    {
        icon: "website",
        title: "Web Development",
        url: "web-development",
    },
    {
        icon: "mobile",
        title: "Mobile Development",
        url: "mobile-development",
    },
    {
        icon: "software",
        title: "Software Development",
        url: "software-development",
    },
    {
        icon: "development",
        title: "DevOps Consulting",
        url: "devops-consulting",
    },
    {
        icon: "cloud",
        title: "Big Data",
        url: "big-data"
    },
]);
const otherServices = ref([
    
    {
        icon: "research",
        title: "Market Research",
        url: "market-research",
    },
    {
        icon: "chess",
        title: "Digital Strategy",
        url: "digital-strategy",
    },
    {
        icon: "design",
        title: "UI/UX Design",
        url: "ui-ux-design",
    },
]);
</script>

<template>
    <section class="container px-5 md:px-7 lg:px-14">
        <div class="py-20">
            <div class="grid gap-4 grid-cols-1 lg:grid-cols-2">
                <div class="">
                    <div>
                        <Tag tagClass="bg-black87 text-white">How can we help?</Tag>
                        <h2 class="mt-4 max-w-xl">
                            <SplitAnimate :duration="0.4" text="Innovative services for modern demands" />
                        </h2>
                    </div>
                    <div class="description mt-10 text-black60 max-w-lg">
                        Our innovative services are designed to meet the modern
                        demands of startups and enterprises in today's
                        fast-paced world.
                    </div>
                </div>
                <div class="">
                    <!-- <div class="flex w-100 items-center">
                        <hr class="flex-1">
                        <h3 class="my-4 px-2">Core Services</h3>
                        <hr class="flex-1">
                    </div> -->
                    <div class="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
                        <div v-for="(service, index) in coreServices">
                            <CardLink 
                                :key="index"
                                :icon="service.icon"
                                :url="`/services/${service.url}`"
                                :title="service.title"
                            />
                        </div>
                    </div>
                    <!-- <div class="flex w-100 items-center">
                        <hr class="flex-1">
                        <h3 class="my-4 px-2">Other Services</h3>
                        <hr class="flex-1">
                    </div>
                    <div class="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
                        <div v-for="(service, index) in otherServices">
                            <CardLink 
                                :key="index"
                                :icon="service.icon"
                                :url="`/services/${service.url}`"
                                :title="service.title"
                                v-if="!service.coreService"
                            />
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </section>
    <SectionSep />
</template>
